.btn-secondary.btn-select {
  font-size: 1rem;
  color: #fff;
  background-color: #868e96;
  border: 1px solid #ced4da;
}

.btn-secondary.btn-select:hover {
  border-color: #ced4da;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-secondary.btn-select:focus,
.btn-secondary.btn-select.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


.btn-secondary.btn-select:not([disabled]):not(.disabled):active,
.btn-secondary.btn-select:not([disabled]):not(.disabled).active,
.show>.btn-secondary.btn-select.dropdown-toggle {
  border-color: #ced4da;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-secondary.btn-select.is-valid {
  border-color: #28a745;
}

.btn-secondary.btn-select.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.btn-secondary.btn-select.is-invalid {
  border-color: #dc3545;
}

.btn-secondary.btn-select.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

html,
body {
  font-weight: normal;
  font-style: normal;
  height: 100%;
  min-height: 100%;
  padding-top: 0px;
}

body {
  overflow-y: scroll;
}

.popover-big {
  width: 500px!important;
  max-width: 500px!important;
}
.popoverbody-big {
  width: 460px!important;
  max-width: 460px!important;
}

.lang-dropdown-menu {
  width: 40px!important;
  max-width: 40px!important;
  min-width: 40px!important;
}
.btn-outline-google {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-google:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #6c757d;
}
.date-time-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.popover {
  max-width: 460px;
}