@font-face {
    font-family: 'Open Sans';
    font-weight: 300;
    font-display: fallback;
    font-style: normal;
    src: url('../node_modules/npm-font-open-sans/fonts/Light/OpenSans-Light.eot?display=swap');
    src: url('../node_modules/npm-font-open-sans/fonts/Light/OpenSans-Light.eot?#iefix&display=swap') format('embedded-opentype'),
    url('../node_modules/npm-font-open-sans/fonts/Light/OpenSans-Light.woff2?display=swap') format('woff2'),
    url('../node_modules/npm-font-open-sans/fonts/Light/OpenSans-Light.woff?display=swap') format('woff'),
    url('../node_modules/npm-font-open-sans/fonts/Light/OpenSans-Light.ttf?display=swap') format('truetype'),
    url('../node_modules/npm-font-open-sans/fonts/Light/OpenSans-Light.svg#OpenSansLight?display=swap') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 300;
    font-display: fallback;
    font-style: italic;
    src: url('../node_modules/npm-font-open-sans/fonts/LightItalic/OpenSans-LightItalic.eot?display=swap');
    src: url('../node_modules/npm-font-open-sans/fonts/LightItalic/OpenSans-LightItalic.eot?#iefix&display=swap') format('embedded-opentype'),
    url('../node_modules/npm-font-open-sans/fonts/LightItalic/OpenSans-LightItalic.woff2?display=swap') format('woff2'),
    url('../node_modules/npm-font-open-sans/fonts/LightItalic/OpenSans-LightItalic.woff?display=swap') format('woff'),
    url('../node_modules/npm-font-open-sans/fonts/LightItalic/OpenSans-LightItalic.ttf?display=swap') format('truetype'),
    url('../node_modules/npm-font-open-sans/fonts/LightItalic/OpenSans-LightItalic.svg#OpenSansLightItalic?display=swap') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: normal;
    font-display: fallback;
    font-style: normal;
    src: url('../node_modules/npm-font-open-sans/fonts/Regular/OpenSans-Regular.eot?display=swap');
    src: url('../node_modules/npm-font-open-sans/fonts/Regular/OpenSans-Regular.eot?#iefix&display=swap') format('embedded-opentype'),
    url('../node_modules/npm-font-open-sans/fonts/Regular/OpenSans-Regular.woff2?display=swap') format('woff2'),
    url('../node_modules/npm-font-open-sans/fonts/Regular/OpenSans-Regular.woff?display=swap') format('woff'),
    url('../node_modules/npm-font-open-sans/fonts/Regular/OpenSans-Regular.ttf?display=swap') format('truetype'),
    url('../node_modules/npm-font-open-sans/fonts/Regular/OpenSans-Regular.svg#OpenSansRegular?display=swap') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: normal;
    font-display: fallback;
    font-style: italic;
    src: url('../node_modules/npm-font-open-sans/fonts/Italic/OpenSans-Italic.eot?display=swap');
    src: url('../node_modules/npm-font-open-sans/fonts/Italic/OpenSans-Italic.eot?#iefix&display=swap') format('embedded-opentype'),
    url('../node_modules/npm-font-open-sans/fonts/Italic/OpenSans-Italic.woff2?display=swap') format('woff2'),
    url('../node_modules/npm-font-open-sans/fonts/Italic/OpenSans-Italic.woff?display=swap') format('woff'),
    url('../node_modules/npm-font-open-sans/fonts/Italic/OpenSans-Italic.ttf?display=swap') format('truetype'),
    url('../node_modules/npm-font-open-sans/fonts/Italic/OpenSans-Italic.svg#OpenSansItalic?display=swap') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    font-display: fallback;
    font-style: normal;
    src: url('../node_modules/npm-font-open-sans/fonts/Semibold/OpenSans-Semibold.eot?display=swap');
    src: url('../node_modules/npm-font-open-sans/fonts/Semibold/OpenSans-Semibold.eot?#iefix&display=swap') format('embedded-opentype'),
    url('../node_modules/npm-font-open-sans/fonts/Semibold/OpenSans-Semibold.woff2?display=swap') format('woff2'),
    url('../node_modules/npm-font-open-sans/fonts/Semibold/OpenSans-Semibold.woff?display=swap') format('woff'),
    url('../node_modules/npm-font-open-sans/fonts/Semibold/OpenSans-Semibold.ttf?display=swap') format('truetype'),
    url('../node_modules/npm-font-open-sans/fonts/Semibold/OpenSans-Semibold.svg#OpenSansSemibold?display=swap') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    font-display: fallback;
    font-style: italic;
    src: url('../node_modules/npm-font-open-sans/fonts/SemiboldItalic/OpenSans-SemiboldItalic.eot?display=swap');
    src: url('../node_modules/npm-font-open-sans/fonts/SemiboldItalic/OpenSans-SemiboldItalic.eot?#iefix&display=swap') format('embedded-opentype'),
    url('../node_modules/npm-font-open-sans/fonts/SemiboldItalic/OpenSans-SemiboldItalic.woff2?display=swap') format('woff2'),
    url('../node_modules/npm-font-open-sans/fonts/SemiboldItalic/OpenSans-SemiboldItalic.woff?display=swap') format('woff'),
    url('../node_modules/npm-font-open-sans/fonts/SemiboldItalic/OpenSans-SemiboldItalic.ttf?display=swap') format('truetype'),
    url('../node_modules/npm-font-open-sans/fonts/SemiboldItalic/OpenSans-SemiboldItalic.svg#OpenSansSemiboldItalic?display=swap') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: bold;
    font-display: fallback;
    font-style: normal;
    src: url('../node_modules/npm-font-open-sans/fonts/Bold/OpenSans-Bold.eot?display=swap');
    src: url('../node_modules/npm-font-open-sans/fonts/Bold/OpenSans-Bold.eot?#iefix&display=swap') format('embedded-opentype'),
    url('../node_modules/npm-font-open-sans/fonts/Bold/OpenSans-Bold.woff2?display=swap') format('woff2'),
    url('../node_modules/npm-font-open-sans/fonts/Bold/OpenSans-Bold.woff?display=swap') format('woff'),
    url('../node_modules/npm-font-open-sans/fonts/Bold/OpenSans-Bold.ttf?display=swap') format('truetype'),
    url('../node_modules/npm-font-open-sans/fonts/Bold/OpenSans-Bold.svg#OpenSansBold?display=swap') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: bold;
    font-display: fallback;
    font-style: italic;
    src: url('../node_modules/npm-font-open-sans/fonts/BoldItalic/OpenSans-BoldItalic.eot?display=swap');
    src: url('../node_modules/npm-font-open-sans/fonts/BoldItalic/OpenSans-BoldItalic.eot?#iefix&display=swap') format('embedded-opentype'),
    url('../node_modules/npm-font-open-sans/fonts/BoldItalic/OpenSans-BoldItalic.woff2?display=swap') format('woff2'),
    url('../node_modules/npm-font-open-sans/fonts/BoldItalic/OpenSans-BoldItalic.woff?display=swap') format('woff'),
    url('../node_modules/npm-font-open-sans/fonts/BoldItalic/OpenSans-BoldItalic.ttf?display=swap') format('truetype'),
    url('../node_modules/npm-font-open-sans/fonts/BoldItalic/OpenSans-BoldItalic.svg#OpenSansBoldItalic?display=swap') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 800;
    font-display: fallback;
    font-style: normal;
    src: url('../node_modules/npm-font-open-sans/fonts/ExtraBold/OpenSans-ExtraBold.eot?display=swap');
    src: url('../node_modules/npm-font-open-sans/fonts/ExtraBold/OpenSans-ExtraBold.eot?#iefix&display=swap') format('embedded-opentype'),
    url('../node_modules/npm-font-open-sans/fonts/ExtraBold/OpenSans-ExtraBold.woff2?display=swap') format('woff2'),
    url('../node_modules/npm-font-open-sans/fonts/ExtraBold/OpenSans-ExtraBold.woff?display=swap') format('woff'),
    url('../node_modules/npm-font-open-sans/fonts/ExtraBold/OpenSans-ExtraBold.ttf?display=swap') format('truetype'),
    url('../node_modules/npm-font-open-sans/fonts/ExtraBold/OpenSans-ExtraBold.svg#OpenSansExtrabold?display=swap') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 800;
    font-display: fallback;
    font-style: italic;
    src: url('../node_modules/npm-font-open-sans/fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.eot?display=swap');
    src: url('../node_modules/npm-font-open-sans/fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.eot?#iefix&display=swap') format('embedded-opentype'),
    url('../node_modules/npm-font-open-sans/fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff2?display=swap') format('woff2'),
    url('../node_modules/npm-font-open-sans/fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff?display=swap') format('woff'),
    url('../node_modules/npm-font-open-sans/fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.ttf?display=swap') format('truetype'),
    url('../node_modules/npm-font-open-sans/fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.svg#OpenSansExtraboldItalic?display=swap') format('svg');
}
